import {
    RESET_PRESSUPOST_CONTEXT,
    SAVE_PRESSUPOST_CONTEXT,
    SET_EDIT_MODE_PRESSUPOST,
    SET_PRESSUPOST_FILE
} from '../actions/actionTypes';

const initialState = {
    editMode: false,
    fileUrl: '',
    fileName: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_PRESSUPOST_CONTEXT:
            return {
                ...state,
                ...action.payload
            };
        case RESET_PRESSUPOST_CONTEXT:
            return initialState;
        case SET_EDIT_MODE_PRESSUPOST:
            return {
                ...state,
                editMode: action.payload
            };
        case SET_PRESSUPOST_FILE:
            return {
                ...state,
                fileUrl: action.payload.fileUrl,
                fileName: action.payload.fileName
            };
        default:
            return state;
    }
};

export default reducer;
