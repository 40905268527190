import {RESET_PROCESS_NAV_INFO, SET_PROCESS_NAV_INFO} from '../actions/actionTypes';

const initialState = {
    secuencia: [],
    actual: null,
    goToView: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROCESS_NAV_INFO:
            return {
                ...state,
                secuencia: action.payload.secuencia,
                actual: action.payload.actual,
                goToView: action.payload.goToView
            };
        case RESET_PROCESS_NAV_INFO:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
