import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import configureStore from "./store/configureStore";
import {Provider} from "react-redux";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

    render() {
        const store = configureStore();
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <React.Suspense fallback={loading()}>
                        <Switch>
                            <Route exact path="/login" name="Login Page" component={Login}/>
                            <PrivateRoute path="/" name="Home" component={DefaultLayout}/>
                            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                        </Switch>
                    </React.Suspense>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default App;

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
    )}/>
);
