import {
    RESET_ACTUACIO_REPERTORI_CONTEXT,
    SAVE_ACTUACIO_REPERTORI_CONTEXT,
    SET_ACTUACIO_REPERTORI_FILE,
} from "../actions/actionTypes";

const initialState = {
    selectedActuacio: null,
    fileUrl: '',
    fileName: '',
    selectedRepertori: [],
    repertori: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_ACTUACIO_REPERTORI_CONTEXT:
            return {
                ...state,
                ...action.payload
            };
        case RESET_ACTUACIO_REPERTORI_CONTEXT:
            return initialState;
        case SET_ACTUACIO_REPERTORI_FILE:
            return {
                ...state,
                fileUrl: action.payload.fileUrl,
                fileName: action.payload.fileName
            };
        default:
            return state;
    }
};

export default reducer;
