import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from "redux-thunk";
// Reducers
import authReducer from './reducers/auth';
import processNavigatorReducer from './reducers/processNavigator';
import pressupostosReducer from './reducers/pressupostos';
import contractesReducer from './reducers/contractes';
import repertorisReducer from './reducers/repertoris';
import facturesReducer from './reducers/factures';


const rootReducer = combineReducers({
    auth: authReducer,
    processNavigator: processNavigatorReducer,
    pressupost: pressupostosReducer,
    contractes: contractesReducer,
    repertoris: repertorisReducer,
    factures: facturesReducer
});

const configureStore = () => {
    return createStore(rootReducer, applyMiddleware(thunk));
};

export default configureStore;
