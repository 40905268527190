import {
    RESET_CONTRACTE_CONTEXT,
    SAVE_CONTRACTE_CONTEXT,
    SET_CONTRACTE_FILE,
    SET_EDIT_MODE_CONTRACTE,
} from "../actions/actionTypes";

const initialState = {
    selectedActuacio: null,
    fileUrl: '',
    fileName: ''
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CONTRACTE_CONTEXT:
            return {
                ...state,
                ...action.payload
            };
        case RESET_CONTRACTE_CONTEXT:
            return initialState;
        case SET_EDIT_MODE_CONTRACTE:
            return {
                ...state,
                editMode: action.payload
            };
        case SET_CONTRACTE_FILE:
            return {
                ...state,
                fileUrl: action.payload.fileUrl,
                fileName: action.payload.fileName
            };
        default:
            return state;
    }
};

export default reducer;
