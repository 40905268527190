import {
    RESET_FACTURA_CONTEXT,
    SAVE_FACTURA_CONTEXT,
    SET_EDIT_MODE_CONTRACTE,
    SET_FACTURA_FILE,
} from "../actions/actionTypes";

const initialState = {
    selectedActuacio: null,
    fileUrl: '',
    fileName: '',
    invoice: null,
    error: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_FACTURA_CONTEXT:
            return {
                ...state,
                ...action.payload
            };
        case RESET_FACTURA_CONTEXT:
            return initialState;
        case SET_EDIT_MODE_CONTRACTE:
            return {
                ...state,
                editMode: action.payload
            };
        case SET_FACTURA_FILE:
            return {
                ...state,
                fileUrl: action.payload.fileUrl,
                fileName: action.payload.fileName
            };
        default:
            return state;
    }
};

export default reducer;
