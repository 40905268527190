export const TEST = "TEST";

export const USER_LOGGED = "USER_LOGGED";
export const USER_NOT_LOGGED = "USER_NOT_LOGGED";

export const SET_PROCESS_NAV_INFO = "SET_PROCESS_NAV_INFO";
export const RESET_PROCESS_NAV_INFO = "RESET_PROCESS_NAV_INFO";

export const ACTION_TYPE_PREV = "ACTION_TYPE_PREV";
export const ACTION_TYPE_NEXT = "ACTION_TYPE_NEXT";
export const ACTION_TYPE_CANCEL = "ACTION_TYPE_CANCEL";
export const ACTION_TYPE_END = "ACTION_TYPE_END";

export const SAVE_PRESSUPOST_CONTEXT = "SAVE_PRESSUPOST_CONTEXT";
export const SEND_PRESSUPOST_CONTEXT = "SEND_PRESSUPOST_CONTEXT";
export const RESET_PRESSUPOST_CONTEXT = "RESET_PRESSUPOST_CONTEXT";
export const SET_PRESSUPOST_FILE = "SET_PRESSUPOST_FILE";
export const SET_EDIT_MODE_PRESSUPOST = "SET_EDIT_MODE_PRESSUPOST";


export const SAVE_CONTRACTE_CONTEXT = "SAVE_CONTRACTE_CONTEXT";
export const RESET_CONTRACTE_CONTEXT = "RESET_CONTRACTE_CONTEXT";
export const SET_CONTRACTE_FILE = "SET_CONTRACTE_FILE";
export const SET_EDIT_MODE_CONTRACTE = "SET_EDIT_MODE_CONTRACTE";

export const SAVE_FACTURA_CONTEXT = "SAVE_FACTURA_CONTEXT";
export const RESET_FACTURA_CONTEXT = "RESET_FACTURA_CONTEXT";
export const SET_FACTURA_FILE = "SET_FACTURA_FILE";


export const SAVE_ACTUACIO_REPERTORI_CONTEXT = "SAVE_ACTUACIO_REPERTORI_CONTEXT";
export const RESET_ACTUACIO_REPERTORI_CONTEXT = "RESET_ACTUACIO_REPERTORI_CONTEXT";
export const SET_ACTUACIO_REPERTORI_FILE = "SET_ACTUACIO_REPERTORI_FILE";


export const SAVE_CONTEXT = "SAVE_CONTEXT";

