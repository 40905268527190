import {SAVE_CONTEXT, USER_NOT_LOGGED} from '../actions/actionTypes';

const initialState = {
    user: null,
    userLogged: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CONTEXT:
            return {
                ...state,
                user: action.payload.user,
                userLogged: true
            };
        case USER_NOT_LOGGED:
            return {
                ...state,
                user: null,
                userLogged: false
            };
        default:
            return state;
    }
};

export default reducer;
